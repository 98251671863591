<template>
  <div id="app">
    <Tabs></Tabs>
    <!-- <img class="banner" :src="this.url.imgUrl + banner" alt="" />
    <Notice></Notice>-->
    <div class="content_box">
      <div class="content">
        <!-- 导航 -->
        <div class="nav_box">
          <!-- 一级导航 -->
          <div class="one_box">
            <div>{{ one_name }}</div>
            <div class="choose_btn" @click="dialogVisible = true">
              切换考试
              <img src="../../../assets/img/xuanze.png" alt />
            </div>
          </div>
          <!-- 二级导航 -->
          <div class="two_box">
            <div class="two_left">专业科目</div>
            <div class="two_right">
              <div
                class="major_el"
                :class="[choose_two == item.chapter_category_id ? 'major' : '']"
                v-for="item in two_nav"
                :key="item.chapter_category_id"
                @click="cutList(item)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="card_box">
          <Menu></Menu>
          <!-- 空状态 -->
          <div class="empty_box" v-if="show_card == true">
            <img src="../../../assets/img/cuoti_null.png" alt />
            <p>暂无错题，快去做题吧～</p>
            <button type="button" @click="goTopic">去做题</button>
          </div>
          <div v-else>
            <!-- 题型 -->
            <div class="topic_type">
              <p>题型</p>
              <p>错题数</p>
            </div>
            <!-- 单选/多选 -->
            <div class="topic_box">
              <div
                class="single_choice"
                v-for="item in collectNum"
                :key="item.id"
              >
                <div class="left">
                  <!-- <img src="../../../assets/img/danxuan.png" alt="" /> -->
                  <div v-for="el in coolect_icon" :key="el.qtype">
                    <img v-show="item.qtype == el.qtype" :src="el.img" alt />
                  </div>
                  {{ item.name }}
                </div>
                <div class="right">
                  <div>{{ item.num }}</div>
                  <button type="button" @click="againTopic(item)">
                    重新做题
                  </button>
                  <button type="button" @click="seeAnalysis(item)">
                    查看解析
                  </button>
                </div>
              </div>
              <!-- <div class="single_choice multiple _choice">
              <div class="left">
                <img src="../../../assets/img/duoxuan.png" alt="" />
                {{collectNum[0].name}}
              </div>
              <div class="right">
                <div>{{collectNum[1].num}}</div>
                <button type="button">重新做题</button>
                <button type="button">查看解析</button>
              </div>
              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- dialog框 -->
    <el-dialog title="请选择专业科目" :visible.sync="dialogVisible" width="33%">
      <!-- <span>这是一段信息</span> -->
      <div class="chapter_category_box">
        <div
          class="chapter_category_el"
          v-for="item in one_nav"
          :key="item.id"
          @click="choose_major(item)"
        >
          {{ item.name }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <Foot></Foot>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  data() {
    return {
      banner: "/assets/img/infomation.jpg", // banner
      dialogVisible: false, //弹窗
      one_nav: [], //一级导航
      one_id: "", // 一级导航id
      one_name: "", //一级导航名字
      two_nav: [], //二级导航
      two_name: "", //二级导航名字
      choose_two: "", // 二级导航默认选中
      chapter_category: "", // 一级导航id
      collectNum: [], // 收藏数量
      chapter_category: "", //章节二级分类
      show_card: false, // 是否展示空状态
      coolect_icon: [
        {
          img: require("../../../assets/img/danxuan.png"),
          qtype: 1,
        },
        {
          img: require("../../../assets/img/fenxi.png"),
          qtype: 2,
        },
        {
          img: require("../../../assets/img/stuff_xuanze.png"),
          qtype: 3,
        },
        {
          img: require("../../../assets/img/duoxuan.png"),
          qtype: 4,
        },
        {
          img: require("../../../assets/img/fenxi.png"),
          qtype: 5,
        },
      ],
      empty: false,
    };
  },

  mounted() {},

  methods: {
    // 去做题
    goTopic() {
      this.$router.push({
        name: "Chapter",
        params: { is_pageid: 9, select_id: 1 },
      });
    },
    //   获取一级章节分类
    getOneChapter() {
      api.chapter_category().then((res) => {
        // console.log("一级分类", res);
        if (parseInt(res.data.code) == 1) {
          this.one_nav = res.data.data.list;
          // this.one_id = res.data.data.list[0].id;
          // this.one_name = res.data.data.list[0].name;
          // this.chapter_category = res.data.data.list[0].id;
          // this.getCollectNum(res.data.data.list[0].id);
          // this.chapter_category = res.data.data.list[0].id;

          if (sessionStorage.getItem("chapterName")) {
            this.one_name = sessionStorage.getItem("chapterName");
          } else {
            this.one_name = res.data.data.list[0].name;
          }
          if (sessionStorage.getItem("chapterId")) {
            this.getTwoChapter(sessionStorage.getItem("chapterId"));
            this.chapter_category = sessionStorage.getItem("chapterId");
          } else {
            this.one_id = res.data.data.list[0].id;
            this.getTwoChapter(res.data.data.list[0].id);
            this.chapter_category = res.data.data.list[0].id;
          }
        }
      });
    },
    // 点击一级导航切换章节
    choose_major(el) {
      // // console.log("章节", el);
      //   this.getTwoChapter(el.id);
      this.one_name = el.name;
      this.one_id = el.id;
      this.chapter_category = el.id;
      this.dialogVisible = false;
      this.getTwoChapter(el.id);
      this.chapter_category = el.id;

      sessionStorage.setItem("chapterId", el.id);
      sessionStorage.setItem("chapterName", el.name);
    },
    //   获取二级章节分类
    getTwoChapter(id) {
      var data = {
        chapter_category: id,
        chapter_category_id: id,
      };
      api.chapter_list(data).then((res) => {
        // console.log("二级分类", res);
        if (parseInt(res.data.code) == 1) {
          this.two_nav = res.data.data.list;
          // // console.log(this.two_nav);
          //   this.chapters_list = res.data.data.list[0].chapters;
          this.choose_two = this.two_nav[0].chapter_category_id;
          this.getCollectNum();
          // // console.log(this.two_nav[0].chapter_category_id);
        }
      });
    },
    // 点击二级导航切换列表
    cutList(el) {
      // // console.log("二级导航点击", el);
      //   this.chapters_list = el.chapters;
      this.choose_two = el.chapter_category_id;
      this.getCollectNum();
    },
    // 错题列表接口
    getCollectNum(id) {
      var data = {
        chapter_category: this.one_id,
        three_category_id: this.choose_two,
        // qtype:1
      };
      api.qtype_num(data).then((res) => {
        // console.log("错题列表", res);
        if (parseInt(res.data.code) == 1) {
          this.collectNum = res.data.data.list;
          let nullArr = res.data.data.list;
          // 判断返回列表数组是否为空,空的时候展示空状态
          if (nullArr == [] || nullArr == null || nullArr.length <= 0) {
            this.show_card = true;
          } else {
            this.show_card = false;
          }
        } else if (parseInt(res.data.code) == 401) {
          this.$message.error({
            message: "请先登录!",
            duration: "2000",
          });
          this.$router.push({
            name: "Login",
          });
        }
      });
    },
    // 重新做题
    againTopic(el) {
      console.log(12345);
      // console.log(el);
      // this.chapter_category
      this.$router.push({
        name: "Again",
        params: {
          is_pageid: 9,
          chapter_id: this.chapter_category,
          qtype: el.qtype,
          name: el.name,
          error_collect: "错题本",
        },
        query: {
          id: this.choose_two,
        },
      });
    },
    // 去查看解析页面
    seeAnalysis(el) {
      // // console.log(el);
      this.$router.push({
        // name: "WrongAnalysis",
        // params: {
        //   is_pageid: 9,
        //   chapter_id: this.chapter_category,
        //   qtype: el.qtype,
        //   name: el.name,
        // },
        name: "Analysis",
        params: {
          is_pageid: 9,
          chapter_id: this.chapter_category,
          qtype: el.qtype,
          name: el.name,
          wrong_collect: 0, //0错题本  1收藏
          error_collect: "错题本",
        },
        query: {
          id: this.choose_two,
        },
      });
    },
  },
  created() {
    this.one_id = sessionStorage.getItem("chapterId");
    this.getOneChapter();
  },
};
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 400px;
}
// 内容区
.content_box {
  width: 100%;
  background: #f5f5f5;
  .content {
    width: 1200px;
    margin: auto;
    overflow: hidden;
    //   章节
    .chapter_box {
      width: 1180px;
      height: 70px;
      background: #fff;
      padding-left: 20px;
      margin: 30px 0 20px;
      border-radius: 6px;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
      display: flex;
      align-items: center;
      p {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #222222;
      }
      .btn {
        width: 81px;
        height: 23px;
        border: 1px solid #3e7eff;
        border-radius: 11px;
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #3e7eff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        img {
          width: 12px;
          height: 8px;
          margin-left: 4px;
        }
      }
    }
    .card_box {
      width: 100%;
      height: 740px;
      background: #fff;
      border-radius: 0 0 6px 6px;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
      display: flex;
      flex-flow: column;
      align-items: center;
      // justify-content: center;
      margin-bottom: 227px;
      border-radius: 6px;
      // 空状态
      .empty_box {
        width: 1200px;
        height: 610px;
        background: #fff;
        // margin-top: 131px;
        display: flex;
        flex-flow: column;
        align-items: center;
        // margin-bottom: 225px;
        img {
          width: 286px;
          height: 224px;
          margin-top: 131px;
        }
        p {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #999999;
          margin: 29px 0 15px;
        }
        button {
          width: 200px;
          height: 46px;
          background: #3e7eff;
          border-radius: 6px;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #ffffff;
        }
      }
      div {
        overflow: hidden;
        //   题型
        .topic_type {
          width: 1160px;
          height: 50px;
          border-bottom: 1px solid #f5f5f5;
          display: flex;
          align-items: center;
          p {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #666666;
          }
          p:last-child {
            margin-left: 809px;
          }
        }
        //   单选/多选
        .topic_box {
          width: 1160px;
          .single_choice {
            height: 80px;
            border-bottom: 1px solid #f5f5f5;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left {
              display: flex;
              align-items: center;
              img {
                width: 20px;
                height: 20px;
                margin-right: 12px;
              }
            }
            .right {
              display: flex;
              align-items: center;
              div {
                width: 55px;
                text-align: center;
                margin-right: 51px;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #666;
              }
              button {
                cursor: pointer;
              }
              button:nth-child(2) {
                width: 100px;
                height: 34px;
                background: #3e7eff;
                border-radius: 17px;
                border: none;
                font-size: 15px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #ffffff;
                margin-right: 18px;
              }
              button:nth-child(3) {
                width: 100px;
                height: 34px;
                background: #64c281;
                border-radius: 17px;
                border: none;
                font-size: 15px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  }
}

.nav_box {
  width: 1160px;
  // height: 170px;
  padding: 0 20px;
  background: #fff;
  border-radius: 6px;
  margin: 30px 0;
  overflow: hidden;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
  //   一级导航
  .one_box {
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    margin: 24px 0 26px;
    div {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #222222;
    }
    .select_option {
      position: relative;
      margin-left: 12px;
      /deep/.el-select {
        width: 96px;
        height: 26px;
        background: #3e7eff;
        border-radius: 13px;
        .el-input__inner {
          width: 96px;
          height: 26px;
          // color: red;
          color: rgba(0, 0, 0, 0);
          background: #3e7eff;
          border-radius: 13px;
          padding: 0;
          display: flex;
          justify-content: space-between;
          border: none;
          background-image: url("../../../assets/img/qiehuan.png");
          background-repeat: no-repeat;
          background-size: 13px 12px;
          background-position-x: 72px;
          background-position-y: 7px;
          // display: none;
        }
        .el-input__inner::placeholder {
          color: rgba(0, 0, 0, 0);
          padding-left: 11px;
        }
        .el-input__suffix-inner {
          display: none;
        }
      }

      p {
        position: absolute;
        top: 6px;
        left: 11px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #fff;
        // z-index: -1;
        // z-index: 999;
      }
    }
    .choose_btn {
      width: 81px;
      height: 23px;
      border: 1px solid #3e7eff;
      border-radius: 11px;
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #3e7eff;
      line-height: 23px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      cursor: pointer;
      img {
        width: 12px;
        height: 8px;
        margin-left: 3px;
      }
    }
  }
  //   二级导航
  .two_box {
    width: 100%;
    // min-height: 65px;
    display: flex;
    .two_left {
      width: 60px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #484848;
      padding: 7px 0;
      // padding-top: 7px;
    }
    .two_right {
      width: 997px;
      margin-left: 49px;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 18px;
      .major_el {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #202b3a;
        text-align: center;
        padding: 5px 19px;
        cursor: pointer;
      }
      .major {
        color: #3e7eff;
        // background: #3e7eff;
        border-radius: 18px;
        // max-height: 30px;
        // padding-bottom: 7px;
      }
    }
  }
}
.one_box::after {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  background: #f5f5f5;
  position: absolute;
  bottom: -14px;
  left: 0;
}
.chapter_category_box {
  width: 100%;
  display: flex;
  .chapter_category_el {
    cursor: pointer;
    margin-right: 30px;
  }
  .chapter_category_el:hover {
    color: #3e7eff;
  }
}
</style>
